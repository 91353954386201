import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Spacing from '../components/partials/Spacing'
import Layout from '../components/Layout'
import { initializeVideos } from '../helpers/initializeVideo'

const portalStart = () => {
  const data = useStaticQuery(graphql`
    query FreeApacheKafkaDeveloperExperienceSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "PortalStart" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    try {
      initializeVideos()
    } catch (err) {
      console.log(err)
    }
    // es-lint-disable-next-line
  }, [])

  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="mobile-margin-top overflow-hidden container-1 mx-auto">
        <div className="d-flex flex-wrap hero-margin align-items-center">
          <div className="col-10 mx-auto mobile-padding-reset text-center ">
            <h1>See how easily you could run your Apache Kafka with Lenses</h1>
          </div>
        </div>
      </section>

      <Spacing />
      <div className="container-1 mobile-margin-top mobile-margin-bottom">
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-sm-12 col-md-6 mobile-margin-bottom">
            <h2>Done babysitting Apache Kafka?</h2>
            <p>
              Your Lenses demo environment gives you productivity tooling, data
              observability and access controls, complete with sample data and
              flows to get started.
            </p>

            <ol className="pl-2">
              <li className="mb-4">
                <b>
                  {' '}
                  Create an account to demo the Kafka + Lenses dev experience
                </b>
                <br />
                With a Lenses user account, you can try a pre-configured Kafka
                with dummy data in a turnkey demo environment.
              </li>
              <li className="mb-4">
                <b> Add a workspace & deploy instances of Lenses</b>
                <br /> Select Lenses Demo (unless you&apos;d like to deploy
                straight to your MSK cluster) and Lenses will deploy a sandbox
                environment to our cloud.
              </li>
              <li className="mb-4">
                <b> Grab a tea & start streaming</b>
                <br /> Wait whilst the cloudbots build your first Lenses
                workspace, and voila, you have a sandbox environment to operate
                data on any Apache Kafka.
              </li>
            </ol>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="cloud-aws-msk-video">
              <iframe
                src=""
                data-src="//player.vimeo.com/video/465758340"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            <div className="roll-button mt-4 text-center">
              <a
                className="bg-red text-white "
                href="https://portal.lenses.io/register/"
              >
                <span data-hover="Create account &rarr;">
                  Create account &rarr;
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Spacing />
    </Layout>
  )
}

export default portalStart
